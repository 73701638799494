import React from "react";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import { parseSlug } from "../utils/utils";
import { renderRichText } from "../utils/renderRichText";
import { CUSTOM_TYPES } from "../utils/renderRichText/TypesDefs/custom";
import { TABLE } from "../utils/renderRichText/TypesDefs/table";

import Blockquote from "./blockquote";
import ImageFrame from "./imageFrame";
import CustomProductList from "./blocks/customProductList";
import MarketSchedule from "./blocks/marketSchedule";
import UpdatesFromTheStudio from "./blocks/updatesFromTheStudio";
import PageSample from "./blocks/pageSample";
import PostsList from "./blocks/postsList";
import FeaturedProductList from "./blocks/featuredProductList";

const headingMb = (next) =>
  next?.nodeType?.includes("heading")
    ? "mb-2"
    : next?.nodeType === BLOCKS.HR
    ? "mb-5"
    : "mb-10";

const RenderRichText = ({ richText, options }) => {
  let optionsRenderFuncs = options?.renderFuncs;
  delete options?.renderFuncs;

  let componentOptions = {
    ...options,
    renderFuncs: {
      [BLOCKS.PARAGRAPH]: (node, children, next) => (
        <p
          className={classNames(
            "p-alt text-center",
            next?.nodeType === BLOCKS.PARAGRAPH ? "mb-5" : "mb-10"
          )}
        >
          {children}
        </p>
      ),
      [BLOCKS.HEADING_1]: (node, children, next) => (
        <h1
          className={classNames(
            "text-6xl text-center font-new-tegomin",
            headingMb(next)
          )}
        >
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node, children, next) => (
        <h2
          className={classNames(
            "text-5xl text-center font-new-tegomin",
            headingMb(next)
          )}
        >
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node, children, next) => (
        <h3
          className={classNames(
            "text-4xl text-center font-new-tegomin",
            headingMb(next)
          )}
        >
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node, children, next) => (
        <h4
          className={classNames(
            "text-3xl text-center font-new-tegomin",
            headingMb(next)
          )}
        >
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (node, children, next) => (
        <h5
          className={classNames(
            "text-2xl text-center font-new-tegomin",
            headingMb(next)
          )}
        >
          {children}
        </h5>
      ),
      [BLOCKS.HEADING_6]: (node, children, next) => (
        <h6
          className={classNames(
            "text-xl text-center font-new-tegomin",
            headingMb(next)
          )}
        >
          {children}
        </h6>
      ),
      [BLOCKS.QUOTE]: (node) => (
        <Blockquote>{node.content[0].content[0].value}</Blockquote>
      ),
      [BLOCKS.HR]: () => <hr className="w-1/3 mx-auto mb-10" />,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="text-lg max-w-lg mx-auto mb-10">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="text-lg max-w-lg mx-auto mb-10">{children}</ol>
      ),
      [CUSTOM_TYPES.EMBEDDED_ASSET_GROUP]: (node, children) => (
        <div className="flex flex-col md:flex-row justify-center items-center gap-10">
          {children}
        </div>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <ImageFrame
          key={node.data.target.title}
          image={getImage(node.data.target.gatsbyImageData)}
          alt={node.data.target.title}
          caption={node.data.target.description}
        />
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        switch (node.data.target.__typename) {
          case "ContentfulBlock":
            switch (node.data.target.contentful_id) {
              case "3NhPkTkPuxJOwwrkWO6pYt": // Custom Product list
                return <CustomProductList />;

              case "3PxbMXhRAak3zFbVO5fYPW": // Featured Product List
                return <FeaturedProductList />;

              case "XwvE4r5PGhj2Qshhu38ti": // Market Schedule
                return <MarketSchedule />;

              case "64AyklbC7d7B6LTX91xmbR": // Updates from the Studio
                return <UpdatesFromTheStudio />;

              case "7ML7eiUuLtrYu1wRHe4fR8": // Posts List
                return <PostsList />;

              default:
                console.error(
                  "Could not find block of id ",
                  node.data.target.contentful_id
                );
                return <></>;
            }

          case "ContentfulPage":
            return <PageSample node={node.data.target} />;

          default:
            console.error(
              "Could not find case for content type ",
              node.data.target.__typename
            );
            return <></>;
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => (
        <Link
          to={parseSlug(node.data.target.slug)}
          className="text-white bg-studio-burnt-umber px-2 py-1"
          style={{ backgroundColor: node.data.target.accentColor }}
        >
          {node.data.target.title}
        </Link>
      ),
      [TABLE.TABLE]: (node, children) => (
        <div className="overflow-x-auto mb-10">
          <table className="table-auto w-full max-w-4xl mx-auto">
            {children}
          </table>
        </div>
      ),
      [TABLE.TABLE_CELL]: (node, children) => (
        <td className="border p-3" style={{ minWidth: "10rem" }}>
          {children}
        </td>
      ),
      [TABLE.TABLE_HEADER_CELL]: (node, children) => (
        <th className="border p-3 bg-gray-500" style={{ minWidth: "10rem" }}>
          {children}
        </th>
      ),
      [CUSTOM_TYPES.BUTTON_SINGLE]: (node, children) => (
        <div className="flex justify-center flex-wrap gap-4 mb-10">
          {children}
        </div>
      ),
      [CUSTOM_TYPES.BUTTON_GROUP]: (node, children) => (
        <div className="flex justify-center flex-wrap gap-4 max-w-4xl mx-auto mb-10">
          {children}
        </div>
      ),
      [CUSTOM_TYPES.BUTTON]: (node, children) =>
        /http/.test(node.data.uri) ? (
          <a
            href={node.data.uri}
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ) : (
          <Link to={node.data.uri} className="btn btn-primary">
            {children}
          </Link>
        ),
      ...optionsRenderFuncs,
    },
  };

  return <>{renderRichText(richText, componentOptions)}</>;
};

export default RenderRichText;

export const query = graphql`
  fragment PageTemplateFields on ContentfulPage {
    title
    body {
      raw
      references {
        ... on ContentfulAsset {
          __typename
          contentful_id
          gatsbyImageData(width: 900, placeholder: BLURRED, quality: 70)
          title
          description
        }
        ... on ContentfulPage {
          __typename
          contentful_id
          title
          body {
            raw
          }
          accentColor
          slug
        }
        ... on ContentfulBlock {
          __typename
          contentful_id
          name
        }
      }
    }
    topperImage {
      title
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 70)
    }
    accentColor
    slug
  }

  fragment PostTemplateFields on ContentfulPost {
    title
    createdAt
    updatedAt
    body {
      raw
    }
    metadata {
      tags {
        name
      }
    }
    slug
  }
`;
