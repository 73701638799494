import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import ProductListing from "../productListing";

const CustomProductList = () => {
  const { customizable } = useStaticQuery(graphql`
    query {
      customizable: shopifyCollection(title: { eq: "Customizable" }) {
        products {
          ...ProductCard
        }
      }
    }
  `);

  return <ProductListing products={customizable.products} />;
};

export default CustomProductList;
