import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const MarketSchedule = () => {
  const { events } = useStaticQuery(graphql`
    query {
      events: allContentfulEvent(sort: { fields: dates___dates___date }) {
        nodes {
          name
          city
          location
          address {
            lat
            lon
          }
          eventDates: childContentfulEventDatesJsonNode {
            dates {
              date(formatString: "yyyy-MM-DD")
              end_time
              start_time
            }
          }
          isTentative: tentative
          hasJury: juried
        }
      }
    }
  `);

  let lastMonth = "January";
  let lastYear = "2022";

  return (
    <>
      <h2 className="text-5xl text-center font-new-tegomin mb-10 mt-5">
        Our Schedule
      </h2>

      <ul className="list-none text-center p-0 space-y-4">
        {events.nodes.map(
          (
            { name, city, location, address, eventDates, isTentative, hasJury },
            i
          ) => {
            let eventElem = (
              <Event
                key={i}
                lastMonth={lastMonth}
                lastYear={lastYear}
                {...{
                  name,
                  city,
                  location,
                  address,
                  dates: eventDates.dates,
                  isTentative,
                  hasJury,
                }}
              />
            );
            let month = moment(eventDates.dates[0].date).format("MMMM");
            if (lastMonth !== month) lastMonth = month;
            let year = moment(eventDates.dates[0].date).format("YYYY");
            if (lastYear !== year) lastYear = year;
            return eventElem;
          }
        )}
        <div
          className="w-full !mt-0 mx-auto border border-t-0 border-gray-600"
          style={{ maxWidth: 550 }}
        ></div>
      </ul>
    </>
  );
};

export default MarketSchedule;

const Event = ({
  lastMonth,
  lastYear,
  name,
  city,
  location,
  address,
  dates,
  comments,
  isTentative,
  hasJury,
}) => {
  let isMultipleDays = dates.length > 1;
  let month = moment(dates[0].date).format("MMMM");
  let year = moment(dates[0].date).format("yyyy");

  return (
    <>
      {lastYear !== year && (
        <div className="sticky top-0 bg-studio-charcoal z-20">
          <h3 className="h2 pt-3">{year}</h3>
          <hr className="w-full mx-auto" style={{ maxWidth: 600 }} />
        </div>
      )}
      {lastMonth !== month && lastYear === year && (
        <div
          className="w-full !mt-0 mx-auto  border border-t-0 border-gray-600"
          style={{ maxWidth: 550 }}
        ></div>
      )}
      {lastMonth !== month && (
        <>
          <h4
            className="h3 sticky bg-studio-charcoal w-full mx-auto mb-0 px-3 py-3 border border-b-0 border-gray-600 z-10"
            style={{ maxWidth: 550, top: 60 }}
          >
            {month}
          </h4>
        </>
      )}
      <li
        className="w-full !mt-0 mx-auto px-3 pb-3 border-r border-l border-gray-600"
        style={{ maxWidth: 550 }}
      >
        <div className="flex border border-studio-burnt-umber">
          <div
            className="flex flex-col justify-center items-center bg-studio-burnt-umber text-gray-800 text-lg font-bold p-2"
            style={{ minWidth: 90 }}
          >
            <span>{moment(dates[0].date).format("MMM")}</span>
            <span>
              {!isMultipleDays
                ? moment(dates[0].date).format("Do")
                : moment(dates[0].date).format("D") +
                  "-" +
                  moment(dates[dates.length - 1].date).format("Do")}
            </span>
          </div>
          <div className="flex-grow flex flex-col items-center md:flex-row">
            <div className="flex-grow flex flex-col items-center">
              {isTentative && (
                <div className="self-center bg-red-400 rounded-b px-6 py-1">
                  {hasJury && "Juried "}Tentative
                </div>
              )}
              <div className="p-3">
                {name && <span className="block h3 !mb-2">{name}</span>}
                {location && (
                  <>
                    <span className="font-bold">{location}</span>
                    {" - "}
                  </>
                )}
                {city}
                {!isMultipleDays ? (
                  dates[0].start_time &&
                  dates[0].end_time && (
                    <div>
                      {moment(dates[0].start_time, "h:mma").format("ha")} -{" "}
                      {moment(dates[0].end_time, "h:mma").format("ha")}
                    </div>
                  )
                ) : (
                  <>
                    {dates.map(({ date, start_time, end_time }, i) => (
                      <div key={i}>
                        {moment(date).format("dddd Do")}{" "}
                        {moment(start_time, "h:mma").format("ha")} -{" "}
                        {moment(end_time, "h:mma").format("ha")}
                      </div>
                    ))}
                  </>
                )}
                {comments && <div className="border p-1 mt-3">{comments}</div>}
              </div>
            </div>
            {address && (
              <div className="flex items-center px-5 py-2">
                <a
                  className="flex flex-col items-center font-bold"
                  title="Directions"
                  href={`http://www.google.com/maps/place/${address.lat},${address.lon}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-3xl" />
                  Map
                </a>
              </div>
            )}
          </div>
        </div>
      </li>
    </>
  );
};
