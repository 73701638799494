import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import { renderRichText } from "../../utils/renderRichText";

const PostsList = () => {
  const { posts } = useStaticQuery(graphql`
    query {
      posts: allContentfulPost(sort: { fields: createdAt, order: DESC }) {
        nodes {
          title
          body {
            raw
          }
          slug
        }
      }
    }
  `);

  return (
    <>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-row gap-4">
        {posts.nodes.map((post, i) => (
          <div
            key={i}
            className="flex flex-col items-start border border-studio-light-gray p-3"
          >
            <Link
              to={post.slug}
              className="flex-grow mb-3 text-3xl font-new-tegomin"
            >
              {post.title}
            </Link>

            <div className="mb-3">
              <div className="line-clamp-4 overflow-hidden">
                {renderRichText(post.body, { isSample: true })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PostsList;
