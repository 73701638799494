import React from "react";
import classNames from "classnames";

const Blockquote = ({ children, className, ...props }) => (
  <blockquote
    className={classNames(
      "p-alt text-center mb-10 p-4 border-4 border-gray-900 bg-gray-700",
      className
    )}
    {...props}
  >
    {children}
  </blockquote>
);

export default Blockquote;
