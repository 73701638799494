import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import RenderRichText from "../renderRichText";
import Letter from "../letter";

const UpdatesFromTheStudio = () => {
  const { posts } = useStaticQuery(graphql`
    query {
      posts: allContentfulPost(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        nodes {
          title
          createdAt
          body {
            raw
          }
        }
      }
    }
  `);

  let recentPost = posts.nodes[0];

  return (
    <Letter className="my-14 mx-auto py-14 px-10 max-w-screen-lg">
      <h2 className="text-5xl text-center font-new-tegomin">
        Updates from the Studio
      </h2>
      <hr className="w-1/3 mx-auto mb-10 border-2 border-studio-charcoal" />

      <h3 className="text-4xl text-center font-new-tegomin mb-6">
        {recentPost.title}
      </h3>
      {/* <h4 className="text-2xl text-center font-new-tegomin">
        {moment(recentPost.createdAt).format("MMM DD, YYYY")}
      </h4> */}

      <RenderRichText richText={recentPost.body} />

      <div className="flex justify-center">
        <Link to="/blog/" className="btn btn-primary">
          Read More Updates
        </Link>
      </div>
    </Letter>
  );
};

export default UpdatesFromTheStudio;
