import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import ProductListing from "../productListing";

const FeaturedProductList = () => {
  const { featured } = useStaticQuery(graphql`
    query {
      featured: shopifyCollection(
        id: { eq: "9d91d5fd-3c94-5e8f-9044-fe23d5f6b4f6" }
      ) {
        products {
          ...ProductCard
        }
      }
    }
  `);

  return (
    <div className="max-w-4xl mx-auto overflow-x-auto">
      <ProductListing
        products={featured.products}
        hidePrices={true}
        className="!flex flex-nowrap my-6"
        cardClassName="w-64 min-w-64 max-w-64"
      />
    </div>
  );
};

export default FeaturedProductList;
