import classNames from "classnames";
import React from "react";

const Letter = ({ className, children }) => (
  <div className={classNames("paper-edge text-studio-charcoal", className)}>
    {children}
  </div>
);

export default Letter;
