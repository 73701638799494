import React from "react";
import { Link } from "gatsby";

import { parseSlug } from "../../utils/utils";
import { renderRichText } from "../../utils/renderRichText";

const PageSample = ({ node: { title, body, slug } }) => {
  return (
    <div className="max-w-2xl border border-studio-alt-white mb-10 mx-auto">
      <div className="bg-gray-500 border-b border-studio-alt-white p-3">
        <span className="text-3xl font-bold">{title}</span>
      </div>
      <div className="p-3">
        <div className="line-clamp-4 overflow-hidden">
          {renderRichText(body, { isSample: true })}
        </div>
      </div>
      <div className="border-t border-studio-alt-white p-3">
        <Link to={parseSlug(slug)}>Read More</Link>
      </div>
    </div>
  );
};

export default PageSample;
