import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames";

const ImageFrame = ({
  className,
  frameColor,
  image,
  alt,
  imageProps,
  caption,
  ...props
}) => (
  <div
    className={classNames(
      "max-w-xl mb-10 image-frame",
      `bg-${frameColor ? frameColor : "studio-burnt-umber"}`,
      caption ? "with-caption" : "",
      className
    )}
    {...props}
  >
    <GatsbyImage
      image={image}
      alt={alt}
      style={{ maxHeight: "700px", ...imageProps?.style }}
      {...imageProps}
    />
    {caption && <span>{caption}</span>}
  </div>
);

export default ImageFrame;
